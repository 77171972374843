import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import SearchInput from "../Form/SearchInput";
import useCategory from "../../hooks/useCategory";
import { useCart } from "../../context/Cart";
import { Badge } from "antd";
import logoMain from "../../pages/images/Header-logo.png";
import "../../styles/Homepage.css";

const Header = () => {
  const [auth, setAuth] = useAuth();
  const [cart] = useCart();
  const categories = useCategory();
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    sessionStorage.removeItem("auth");
    toast.success("Logout Successfully");
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <Link to="/" className="navbar-brand">
              <img src={logoMain} alt="Logo" className="navbar-logo" />
            </Link>
            <div className="mx-auto">
              <ul className="navbar-nav mb-2 mb-lg-0">
                <li className="nav-item pe-auto">
                  <NavLink to="/" className="nav-link custom-nav-link">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item pe-auto">
                  <NavLink
                    to="/AllProducts"
                    className="nav-link custom-nav-link"
                  >
                    Shop
                  </NavLink>
                </li>
                <li className="nav-item pe-auto">
                  <div class="dropdown nav-link custom-nav-link">
                    <div
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Design Your Jacket
                    </div>
                    <ul class="dropdown-menu">
                      <li>
                        <Link
                          className="dropdown-item custom-dropdown-item text-black"
                          to={"/design"}
                        >
                          How To design
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item custom-dropdown-item"
                          to="/customJacket"
                        >
                          Design Custom Jacket
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <Link
                  to={"/bulkorder"}
                  className="text-decoration-none text-black"
                >
                  <li className="nav-item nav-link custom-nav-link pe-auto">
                    Bulk Orders
                  </li>
                </Link>
                <li className="nav-item nav-link custom-nav-link pe-auto">
                  <Link
                    to={"/about"}
                    className="text-decoration-none text-black"
                  >
                    About us
                  </Link>
                </li>
                <li className="nav-item pe-auto">
                  <div class="dropdown nav-link custom-nav-link">
                    <div
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Guides
                    </div>
                    <ul class="dropdown-menu">
                      <li>
                        <Link
                          className="dropdown-item custom-dropdown-item pe-auto"
                          to={"/sizechart"}
                        >
                          Size Chart
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item custom-dropdown-item pe-auto"
                          to={"/varcity"}
                        >
                          Varsity Jackets
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item custom-dropdown-item pe-auto"
                          to={"/patches"}
                        >
                          Patches/embroideries
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item custom-dropdown-item pe-auto"
                          to={"/faq"}
                        >
                          Frequemtly asked questions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item nav-link custom-nav-link"><Link className="text-decoration-none text-black" to={'/blog'}>
                  Blogs
                </Link></li>
                <li className="nav-item nav-link custom-nav-link">
                  <Link
                    to={"/contact"}
                    className="text-decoration-none text-black"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {auth?.user && (
                <li className="nav-item dropdown">
                  <NavLink
                    className="nav-link dropdown-toggle custom-nav-link"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                    </svg>
                  </NavLink>
                  <ul className="dropdown-menu custom-dropdown-menu">
                    <li>
                      <NavLink
                        to={`/dashboard/${
                          auth?.user?.role === 1 ? "admin" : "user"
                        }`}
                        className="dropdown-item custom-dropdown-item"
                      >
                        Dashboard
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={handleLogout}
                        to="/login"
                        className="dropdown-item custom-dropdown-item"
                      >
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}
              {!auth?.user ? (
                <>
                  <li className="nav-item">
                    <NavLink
                      to="/register"
                      className="nav-link custom-nav-link"
                    >
                      Register
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/login" className="nav-link custom-nav-link">
                      Login
                    </NavLink>
                  </li>
                </>
              ) : null}
              <li className="nav-item">
                <Badge count={cart?.length} showZero>
                  <NavLink to="/cart" className="nav-link custom-nav-link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-minecart"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2m0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4m8-1a1 1 0 1 1 0-2 1 1 0 0 1 0 2m0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4M.115 3.18A.5.5 0 0 1 .5 3h15a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 14 12H2a.5.5 0 0 1-.491-.408l-1.5-8a.5.5 0 0 1 .106-.411zm.987.82 1.313 7h11.17l1.313-7z" />
                    </svg>
                  </NavLink>
                </Badge>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
