import React, { useState, useEffect } from "react";
import Layout from "../components/layout/Layout";
import {
  AiOutlineReload,
  AiOutlineFilter,
  AiOutlineClose,
} from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../constants/url";
import useCategory from "../hooks/useCategory";
import Loader from "../components/Loader";

const AllProducts = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const categories = useCategory();
  const [colors , setColors] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false); // State to toggle filters on mobile

  const handleFilter = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/api/v1/product/product-filters/?category=${selectedCategory}&color=${selectedColor}`
      );
      setProducts([...data?.products]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getProperties = async () => {
    const { data} = await axios.get('/api/v1/custom/get-properties')
    setColors(data.colors)
}

  useEffect(() => {
    handleFilter();
    getProperties()
  }, [selectedCategory, selectedColor]);

  return (
    <Layout title={"All Products - Best offers"}>
      <div className="container-fluid">
        <div className="row main text-center" style={{gap : '30px'}}>
          {/* Filter Toggle Button for Mobile */}
          <div className="col-12 d-md-none text-end mb-3">
            <button
              className="btn btn-primary"
              onClick={() => setShowFilters(!showFilters)}
            >
              <AiOutlineFilter className="me-2" />
              Filter
            </button>
          </div>

          {/* Sidebar */}
          <div
            className={`col-md-3 sidebar p-3 bg-light shadow ${
              showFilters ? "d-block" : "d-none d-md-block"
            }`}
          >
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="mb-0">Filters</h5>
              {/* Close Button for Mobile */}
              <AiOutlineClose
                className="d-md-none"
                style={{ cursor: "pointer" }}
                onClick={() => setShowFilters(false)}
              />
            </div>

            <h5 className="mb-4">Filter by Category</h5>
            <ul className="list-group">
              <li
                className="list-group-item list-group-item-action"
                style={{ cursor: "pointer" }}
                onClick={() => setSelectedCategory("")}
              >
                All
              </li>
              {categories.map((category, index) => (
                <li
                  key={index}
                  className="list-group-item list-group-item-action"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedCategory(category._id)}
                >
                  {category?.name}
                </li>
              ))}
            </ul>

            <h5 className="mt-4 mb-3">Filter by Color</h5>
            <select
              className="form-select mb-4"
              value={selectedColor}
              onChange={(e) => setSelectedColor(e.target.value)}
            >
              
              <option value="">All Colors</option>
              {colors.map((c, key) =>(
              <option key ={key} value={c?._id}>{c?.name}</option>
                 ))}
              {/* Add more colors as needed */}
            </select>
          </div>

          {/* Products Section */}
          <div className="col-md-6 ">
            {loading ? (
              <Loader/>
            ) : (
              <div className="row product-grid" >
              {products.length !== 0  ? products?.map((p,key) =>(
                <div key={key}>
                 <Link key={key} to={ `/product/${p?._id}`} >
                <img src={p?.frontImage}
                onMouseEnter={(e) => {
                  e.preventDefault()
                  e.currentTarget.src = `${p?.otherImages[0]}`
                }}
                onMouseOut={(e) =>{
                  e.currentTarget.src = `${p?.frontImage}`
                }} 
                className="card-img-top" alt="Product Name" style={{ width :'16rem',  height :'20rem' , objectFit: 'contain' , margin : '0 auto'}}/>
                </Link>
                <div className="">
                    <h5 className="card-title" style = {{ fontSize :'14px'}}>{p?.name.toUpperCase()}</h5>
                    <p style ={{ fontWeight : 700}}>${p?.discountPrice ? (<s style ={{ color : "red"}}>{p?.standardPrice}</s>) :'' }{"  "}{Math.floor(p?.standardPrice - (100 * p?.discountPrice) / p?.standardPrice)}</p>
                </div> 
                </div>
                                       
              ))
            : <p style={{ margin : "0 auto", width : "100%"}}>No product available</p>}
            
            </div>
            )}
          </div>
        </div>
      </div>

      {/* Styling Section */}
      <style jsx>{`
  .sidebar {
    border-radius: 10px;
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: auto;
    z-index: 1000;
  }

  .product-card {
    width: 200px; /* Ensure the card takes up the full width of its grid cell */
    max-width: 300px; /* Optional: Set a max-width if you want to limit the size */
    margin: auto; /* Center the card horizontally within its grid cell */
    border-radius: 15px;
    transition: transform 0.3s ease;
    height: 100%;
  }

  .product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  .product-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(280px, 1fr));
    gap: 1rem; /* Decreased gap between cards */
    justify-items: center;
  }

  .product-card img {
    width: 100%; /* Ensure the image fills the card width */
    height: auto; /* Maintain the aspect ratio */
    object-fit: contain; /* Ensure the image fits inside the container without distortion */
  }

  .card-body {
    padding: 1rem;
  }

  @media (max-width: 768px) {
    .product-grid {
      grid-template-columns: repeat(1, 1fr); /* Single-column on mobile */
    }
    .sidebar {
      display: none;
    }
  }

  @media (min-width: 769px) {
    .sidebar {
      display: block;
      position: relative;
      height: auto;
    }
    .main{
    padding-right:0px;
    }
  }
`}</style>

    </Layout>
  );
};

export default AllProducts;
