import React, { useState, useEffect } from "react";
import Layout from "../components/layout/Layout";
import { useCart } from "../context/Cart";
import { useAuth } from "../context/auth";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

import "../styles/CartStyles.css";
import { Button } from "antd";
import { useStripe } from "@stripe/react-stripe-js";

const CartPage = () => {
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const [cartData, setCartData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [clientToken, setClientToken] = useState("");
  const [instance, setInstance] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const stripe = useStripe();
  //total price
  const totalPrice = () => {
    try {
      let total = 0;
      [...cart]?.map((item) => {
        total = total + (item?.designId?.custom_price ? item.designId.custom_price : item.standardPrice) * item.quantity ;
      });
      return total.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const checkCart = (pid) => {
    const productexist =
      JSON.parse(sessionStorage.getItem("cart")).filter((c) => {
        return c._id === pid;
      }).length === 0
        ? false
        : true;
    console.log(productexist, "exists");
    return productexist;
  };

  const getCarts = async () => {
    try {
      const index = searchParams.get("index").split(",");
      const { data } = await axios.post("/api/v1/custom/getAllCart", {
        carts: [...index],
      });

      data.data.map((product) => {
        checkCart(product?._id);
        if (!checkCart(product?._id)) {
          const cartStorage = JSON.parse(sessionStorage.getItem("cart"));
          sessionStorage.setItem(
            "cart",
            JSON.stringify([
              ...cartStorage,
              {
                ...product,
                size: product.designId.sizes.size,
                quantity: 1,
                custom: true,
              },
            ])
          );
          setCart(JSON.parse(sessionStorage.getItem("cart")));
        }
      });
      toast.success("Item Added to cart");
    } catch (error) {
      console.log(error);
    }
  };
  //detele item
  const removeCartItem = (pid) => {
    try {
      let myCart = [...cart];
      let index = myCart.findIndex((item) => item._id === pid);
      myCart.splice(index, 1);
      setCart(myCart);
      sessionStorage.setItem("cart", JSON.stringify(myCart));
    } catch (error) {
      console.log(error);
    }
  };

  const updateQuantity = (pid, quantity) => {
    console.log(pid, quantity);
    let myCart = [...cart];
    let index = myCart.findIndex((item) => item._id === pid);
    myCart[index].quantity = quantity;
    setCart(myCart);
    sessionStorage.setItem("cart", JSON.stringify(myCart));
    // setCart(sessionStorage.setItem("cart" ,JSON.stringify({...myCart})))
  };

  const handleCheckout = async () => {
    const productlist = cart.map((c) => {
      return {
        designId: c?.designId?._id,
        name: c?.designId?.globals?.catName,
        quantity: c?.quantity,
        price: c?.designId?.custom_price,
      };
    });
    const {
      data: { id },
    } = await axios.post("/api/v1/payment/create-checkout-session", {
      products: productlist,
    });

    const { error } = await stripe.redirectToCheckout({ sessionId: id });

    if (error) {
      console.error("Error during redirect to checkout:", error);
    }
  };

  //handle payments
  // const handlePayment = async () => {
  //   try {
  //     setLoading(true);
  //     const { nonce } = await instance.requestPaymentMethod();
  //     const { data } = await axios.post("/api/v1/product/braintree/payment", {
  //       nonce,
  //       cart,
  //     });
  //     setLoading(false);
  //     sessionStorage.removeItem("cart");
  //     setCart([]);
  //     navigate("/dashboard/user/orders");
  //     toast.success("Payment Completed Successfully ");
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    getCarts();
  }, []);

  return (
    <Layout>
      <div className="container-fluid" style={{ marginTop: "2rem" }}>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div
              className="card p-4 shadow-lg"
              style={{
                backgroundColor: "#343a40",
                color: "#fff",
                borderRadius: "10px",
                border: "none",
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="mb-0">Your Cart</h4>
                <span className="badge bg-info text-dark">{`${cart.length} items`}</span>
              </div>
              <div
                className="cart-items"
                style={{
                  maxHeight: "300px", // Adjust as needed
                  overflowY: "auto",
                }}
              >
                {cart.map((p) => (
                  <div
                    key={p._id}
                    className="cart-item d-flex justify-content-between align-items-center p-2 mb-3 rounded"
                    style={{ backgroundColor: "#454d55" }}
                  >
                    <div className="d-flex align-items-center">
                      <Link
                        to={`/Design/${p?._id}?custom=${p.custom}`}
                      >
                        <img
                          src={p?.frontImage}
                          alt="Design"
                          className="me-3"
                          style={{ width: "50px", borderRadius: "5px" }}
                        />
                      </Link>
                      

                      <div className="d-flex flex-column">

                      <span>{p?.category.name}</span>
                      <span>{p?.standardPrice}$</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
  <button
    className="btn btn-sm btn-outline-light"
    style={{ fontSize: "1rem", padding: "0.2rem 0.6rem" }}
    onClick={() => updateQuantity(p?._id, Math.max(1, p.quantity - 1))}
  >
    -
  </button>
  <span
    className="px-2 mx-2"
    style={{
      border: "1px solid black",
      color: "white",
      width: "35px",
      textAlign: "center",
      borderRadius: "5px",
      backgroundColor: "#454d55",
    }}
  >
    {p.quantity}
  </span>
  <button
    className="btn btn-sm btn-outline-light"
    style={{ fontSize: "1rem", padding: "0.2rem 0.6rem" }}
    onClick={() => updateQuantity(p?._id, p.quantity + 1)}
  >
    +
  </button>
</div>

                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => removeCartItem(p?._id)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>

              <div className="d-flex justify-content-between align-items-center mb-4">
                <span
                  className="text-muted"
                  style={{ textDecoration: "line-through" }}
                >
                  {totalPrice()}
                </span>
                <span className="h4">{totalPrice()}</span>
              </div>

              {auth?.user?.address ? (
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={handleCheckout}
                  >
                    Checkout
                  </button>
                  <button
                    className="btn btn-outline-light btn-lg"
                    onClick={() => navigate("/dashboard/user/profile")}
                  >
                    Update Address
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={() => navigate("/login", { state: "/cart" })}
                  >
                    Please Login to checkout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CartPage;

{
  /* <div className="row">
            <div className="col-lg-7 col-md-12 p-0 m-0">
              {[...cart, ...cartData]?.map((p) => (
                <div className="row card flex-row mb-3" key={p._id}>
                  <div className="col-md-4 col-6">
                    <img
                      src={`${p.designId.custom_image}`}
                      className="card-img-top"
                      alt={p.name}
                      width="100%"
                      height="130px"
                    />
                  </div>
                  <div className="col-md-4 col-6">
                    <p>{p.designId.globals.catName}</p>
                    <p>{p.designId.materials.body}</p>
                    <p>Price: {p.designId.custom_price}$</p>
                  </div>
                  <div className="col-md-4 col-12 mt-3 mt-md-0 d-flex flex-column">
                    <button
                      className="btn btn-danger mb-2"
                      onClick={() => removeCartItem(p._id)}
                    >
                      Remove
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate(`/Design/${p.designId._id}`)}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-5 col-md-12 cart-summary">
              <h2>Cart Summary</h2>
              <p>Total | Checkout | Payment</p>
              <hr />
              <h4>Total: {totalPrice()}</h4>
              {auth?.user?.address ? (
                <div className="mb-3">
                  <h4>Current Address</h4>
                  <h5>{auth?.user?.address}</h5>
                  <button
                    className="btn btn-outline-warning"
                    onClick={() => navigate("/dashboard/user/profile")}
                  >
                    Update Address
                  </button>
                </div>
              ) : (
                <div className="mb-3">
                  {auth?.token ? (
                    <button
                      className="btn btn-outline-warning"
                      onClick={() => navigate("/dashboard/user/profile")}
                    >
                      Update Address
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-warning"
                      onClick={() => navigate("/login", { state: "/cart" })}
                    >
                      Please Login to checkout
                    </button>
                  )}
                </div>
              )}
              <div className="mt-2">
                {!clientToken || !auth?.token || !cartData?.length ? (
                  ""
                ) : (
                  <>
                    <DropIn
                      options={{
                        authorization: clientToken,
                        paypal: {
                          flow: "vault",
                        },
                      }}
                      onInstance={(instance) => setInstance(instance)}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={handlePayment}
                      disabled={loading || !instance || !auth?.user?.address}
                    >
                      {loading ? "Processing ...." : "Make Payment"}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div> */
}
