import React, { useState, useEffect } from "react";
import Layout from "../components/layout/Layout";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/ProductDetailsStyles.css";
import { useCart } from "../context/Cart";
import toast from "react-hot-toast";
import { Carousel } from 'react-responsive-carousel';

const ViewDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [cart, setCart] = useCart();
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const getProduct = async () => {
    try {
      const { data } = await axios.get(`/api/v1/custom/getDesign/${params.id}`);
      setProduct(data.data);
      // .get
      // `/api/v1/product/get-product/${params.slug}` api for getting designs
      // ();
      // console.log(data, "design");
      // setProduct(data?.product);
      // console.log(data.product.category.code, "data product");
      // getSimilarProduct(data?.product._id, data?.product.category._id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async(e) => {
      e.preventDefault()
        await axios.post('/api/v1/custom/share',{
          name : formData.name,
          email: formData.email,
          design : product
        })
  };

  let search = window.location.search;
  let query = new URLSearchParams(search);
  useEffect(() => {
    getProduct();
  }, []);
  console.log(product, "product is here ");
  return (
    <Layout>
      <div className="p-5">
          <Carousel showStatus={false} showArrows={false} autoPlay={true} infiniteLoop={true} interval={3000} >
          <div style={{ width : "16rem" , objectFit : 'contain' , margin : '0 auto'}}>
            <img src={product?.custom_image} className="product-img" alt={"front custom"} width ="20px"/>
          </div>
          <div style={{ width : "8rem" , objectFit : 'contain' ,margin : '0 auto'}}>
            <img src={product?.custom_image_right} className="product-img" alt={"right custom"} width ="20px"/>
          </div>
          <div style={{ width : "16rem" , objectFit : 'contain' , margin : '0 auto'}}>
            <img src={product?.custom_image_back} className="product-img" alt={"back custom"} width ="20px"/>
          </div>
          <div style={{ width : "8rem" , objectFit : 'contain' , margin : '0 auto'}}>
            <img src={product?.custom_image_left} className="product-img" alt={"left custom"} height = "20px"/>
          </div>
          </Carousel>
        </div>
        <div className="p-4">
          <h1 className="text-center mb-4 display-4">Design Details</h1>
          <hr className="mb-4" />
          <h5 className="mb-3">
            <strong>Name:</strong> {product.globals?.catName}
          </h5>
          <p className="mb-3">
            <strong>Description:</strong>
            {""}
          </p>
          <p className="mb-3">
            <strong>Price:</strong>{" "}
          </p>
          <p className="mb-4">
            <strong>Category:</strong>
            {""}
          </p>
          <button
            className="btn btn-primary btn-lg m-4"
            onClick={handleShowModal}
          >
            Share
          </button>
          {query.get("custom") === "false" ?
          <button
            style={{
              marginTop: "5px",
              backgroundColor: "grey",
              color: "white",
            }}
            className="btn btn-lg my-4"
          >
            Edit
          </button> : ""
           }
           {showModal && (
            <div className="modal fade show d-block" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Share Custom Design</h5>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Enter your name"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Enter your email"
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
          )}

          {showModal && <div className="modal-backdrop fade show"></div>}
        </div> 
    </Layout>
  );
};

export default ViewDetails;
